@import url("./utility/theme/antd.css");


@font-face {
	font-family: 'Inter', sans-serif !important;
	/* src: url('/fonts/fontawesome-webfont.eot'); */
	font-weight: normal;
	font-style: normal;

}

* {
	font-family: 'Inter', sans-serif !important;
	color: #FFFFFF;
}

body {
	margin: 0px !important;
}

.mr-2{
  margin-right: 0.5rem;
}

.ml-2{
  margin-left: 0.5rem !important;
}

.pr-2{
	padding-right: 0.5rem;
  }
  
  .pl-2{
	padding-left: 0.5rem !important;
  }

  .text-left {
	text-align: left;
  }
  
  .text-right {
	text-align: right;
  }
