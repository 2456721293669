* {
    font-family: 'Manrope', sans-serif !important;
}

.ant-avatar {
    background-color: rgb(144, 145, 145);
    border: none !important;
    box-shadow: -1px 0px 2px rgb(0, 0, 0);
}

/* =====================description===================== */
.content {
    font-family: 'Manrope';
    font-weight: 400;
    font-size: 14px;
    color: #a6a6a6;
}
.hide {
    display: none;
}
#check {
    display: none;
}
.see-less {
    display: none;
}
label {
    color: #3888ff;
}
#check:checked ~ .hide {
    display: block;
}
#check:checked ~ .see-more {
    display: none;
}
#check:checked ~ .see-less {
    display: block;
}
/* =======================button-and-social================== */
.button-and-social-section {
    width: 100%;
    padding: 0 0 0 30px;
}
