:root {
        --body: '#E2E2E2';
        --primary: '#0D1019';
        --secondary: '#161D2A';
        --danger: '#D44456';
        --white: '#ffffff';
        --lightWhite: "#E8E8EA";
        --box: "#161D2A";
        --cyan: '#74E1CF';
        --gray: '#B9BBBF';
        --black: '#393948';
        --inputbackground: '#EDEFF8';
        --background: '#161D2A';
        --hover: '#EDEFF8';
        --success: "#628c1d";
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 5px;
	background-color: #161D2A
}

::-webkit-scrollbar
{
	width: 6px;
	background-color:#161D2A
}

::-webkit-scrollbar-thumb
{
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #0D1019
}


.dropMenu {
	right: 25px !important;
    top: 60px !important;
	left: unset	 !important;
	width:150px;
	position:fixed
}

.ant-picker-panel {
	background: #161D2A !important;
	color : white !important;
}

.ant-picker-panel-container {
	background: #161D2A !important;
}

.ant-picker-cell-selected > .ant-picker-cell-inner {
	background: #0D1019 !important;
}
.ant-picker-cell-inner > ::before{
	border-color: #0D1019 !important;
}

.ant-select-dropdown {
	background: #161D2A !important;
	color : white !important;
}
.ant-select-item-option-selected {
	background: #0D1019 !important;
	color : white !important;
}
.ant-modal-content {
	background: #0D1019 !important;
}
.ant-modal-title {
	background: #0D1019 !important;
	color: white !important;

}

.ant-picker-time-panel-cell-inner {
	color: white !important;
}

.ant-table {
	background: #161D2A !important;
	color: white !important;
	
}

table {
	background: #161D2A !important;
}
td {
	background: #161D2A !important;
	color: white !important;
}
th {
	background: #161D2A !important;
	color: white !important;
}
.pointer {
	cursor: pointer;
}

.text-center {
	text-align: center;
}
.otp-modal {
	width: fit-content !important;
}
.ant-dropdown-menu  {
	background: #161D2A !important;
	color: white !important;
}
.ant-picker-time-panel-cell-selected > .ant-picker-time-panel-cell-inner {
	background: #0D1019 !important;
}

.ant-picker-cell-range-start > .ant-picker-cell-inner {
	background: #0D1019 !important;
}

.ant-picker-cell-range-end > .ant-picker-cell-inner {
	background: #0D1019 !important;
}


.ant-picker-cell-in-range > .ant-picker-cell-inner {
	background: #0D1019 !important;
}

.ant-picker-cell-in-view::before {
	background: transparent !important;
}

.ant-picker-cell-in-view::after {
	background: transparent !important;
}
.ant-picker-cell-inner::after {
	background: transparent !important;
}

.ant-picker-cell-disabled > .ant-picker-cell-inner {
	background:  #0D1019 !important;
	opacity: 0.4;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
	background-color: "red" !important;
}
